export const getElementAtTopAndBottom = (
    elementIds = [],
    scrollOffset = 0 // You can adjust this value to add some margin if needed
  ) => {
    if (!elementIds || elementIds.length <= 0) return;
  
    const loggedElements = {}; // Object to keep track of logged element IDs
  
    const cleanElementIds = elementIds.map(
      (elementId) =>
        elementId.charAt(0) === "#" ? elementId.substr(1) : elementId // if id has # on the start, remove it
    );
  
    for (const elementId of cleanElementIds) {
      if (loggedElements[elementId]) continue; // Skip if element ID is already logged
  
      const element = document.getElementById(elementId);
      if (!element) continue; // Skip if element with the given ID does not exist
  
      const rect = element.getBoundingClientRect();
  
      // Check if both the top and bottom of the element are within the viewport
      if (rect.top <= scrollOffset && (rect.height + rect.top) >= scrollOffset) {
        loggedElements[elementId] = true;
        return elementId;
      }
  
    }
  
    return null; // Return null if no element is at the top of the page with both top and bottom
  };