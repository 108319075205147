import React from 'react'

import Navbar from './navbar'

const Header = ({ siteTitle }) => {
  const navLinks = [
    {
      text: 'Home',
      link: '/#hero'
    },
    {
      text: 'Services',
      link: '/#services'
    },
    {
      text: 'Projects',
      link: '/#projects'
    },
    {
      text: 'Contact',
      link: '/#contact'
    },
    {
      text: 'Testimonials',
      link: '/#testimonials'
    },
    {
      text: 'About',
      link: '/#about'
    }
  ]
  return (
    <header className='fixed-top w-100'>
      <Navbar siteTitle={siteTitle} navLinks={navLinks} />
    </header>
  )
}

export default Header
