import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import AOS from 'aos'

import Header from './header'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'aos/dist/aos.css'

const theme = {
  color: '#fdcb05',
  rgba: a => `rgba(253, 217, 71, ${a})`,
}

const GlobalStyle = createGlobalStyle`
  html {
  scroll-behavior: smooth;
}
/* -------- STYLE FOR THE SCROLLBAR ------- */
::-webkit-scrollbar {
  width: 7px;
  background-color: var(--dark);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: ${theme.rgba(0.7)};
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: ${theme.color};
}
`

const Main = styled.main`
  /* overflow-x: hidden; */
  width: 100%;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
