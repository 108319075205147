import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { Container, Navbar, NavbarToggler, Collapse, Nav } from 'reactstrap'
import styled from 'styled-components'

import Logo from '../svg/logo.svg'
import { getElementAtTopAndBottom } from '../utils/getElementIdAtViewport'

const CustomeNavbar = styled.nav.attrs({
  className: 'navbar-expand-md',
})`
  transition: background-color 0.5s;
`

const NavbarBrand = styled.a.attrs({
  className: 'd-flex navbar-brand',
})`
  font-weight: 700;
`
const NavItem = styled.li.attrs({
  className: 'nav-item',
})`
  .active {
    background-color: ${props => (props.color ? props.theme.color : 'none')};
    color: ${props => (props.color ? '#000' : props.theme.color)} !important;
    border-radius: 0.25rem !important;
    transition: background-color 0.8s, color 0.5s;
    padding: 8px;
  }
  .active:hover {
    color: #fff !important;
  }
`
const NavLink = styled.a.attrs({
  className: 'nav-link',
})`
  color: #fff !important;

  &:hover {
    color: ${props => props.theme.color} !important;
  }
`
const MyLogo = styled.svg`
  width: ${props => (props.display ? '50px' : '0px')};
  height: 30px;
  fill: #fff;
  transition: width 1s;
`

const MainNavbar = ({ siteTitle, navLinks = [] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [navbarBg, setNavbarBg] = useState('')
  const [activeNav, setActiveNav] = useState('')

  const toggle = () => setIsOpen(!isOpen)
  const closeToggler = () => setIsOpen(false)
  const checkNavbar = () => {
    window.scrollY > 50 || window.innerWidth < 768
      ? setNavbarBg('dark')
      : setNavbarBg('')
  }

  const onScroll = () => {
    handleScrollEvent()
    checkNavbar()
  }

  const isBrower = typeof window !== 'undefined'
  if (isBrower) {
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', checkNavbar)
  }

  // useEffect(() => checkNavbar(), [])

  const handleScrollEvent = () => {
    const linkIds = navLinks?.map(item => item.link.replace('/', ''))
    const elementId = getElementAtTopAndBottom(linkIds, 56)

    if (elementId) {
      setActiveNav(`#${elementId}`)
    }
  }

  useEffect(() => {
    onScroll()

    // cleanup this component
    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', checkNavbar)
    }
  }, [])
  return (
    <CustomeNavbar as={Navbar} color={navbarBg} dark onClick={checkNavbar}>
      <Container>
        <NavbarBrand as={Link} onClick={closeToggler} to='/#'>
          <MyLogo as={Logo} display={navbarBg} />
          {siteTitle}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className='ml-auto' navbar>
            {navLinks.map(navlink => (
              <NavItem
                color={navbarBg}
              >
                <NavLink
                  as={Link}
                  onClick={closeToggler}
                  to={navlink.link}
                  className={
                    navlink.link?.replace('/', '') === activeNav ? 'active' : ''
                  }
                >
                  {navlink.text}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Container>
    </CustomeNavbar>
  )
}

MainNavbar.propTypes = {
  siteTitle: PropTypes.string,
}

MainNavbar.defaultProps = {
  siteTitle: ``,
}

export default MainNavbar
